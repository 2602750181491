import { Box, Button, CardMedia, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useKeyboard } from './Keyboard';
import { useMouse } from './Mouse';
import { usePingPong } from './PingPong';
import { v1 as uuid } from 'uuid';
import { useGamepads } from './Gamepads';

export const Player = () => {

    const { init } = useParams();
    const [name] = useState<string>(uuid());
    const [remote, setRemote] = useState(init || '');
    const videoPlayer = useRef<HTMLVideoElement>(null);
    const pingpong = usePingPong();
    const [dataReady, setDataReady] = useState<boolean>(false);

    useEffect(() => {
        if (!name) return;
        if (!pingpong.setupComplete) {
            console.log('Doing setup');
            pingpong.setup(name, true, 'player');
        }
    }, [pingpong, name]);

    useEffect(() => {
        console.log('Marking pingpong as data ready');
        setDataReady(pingpong.dataReady);
    }, [pingpong.dataReady]);

    useEffect(() => {
        console.log('in video assignment useEffect', pingpong.remoteStream);
        if (!videoPlayer.current) return;
        if (!pingpong.remoteStream) return;
        videoPlayer.current.srcObject = pingpong.remoteStream || null;
        videoPlayer.current.play();
        videoPlayer.current.muted = false;
    }, [pingpong.remoteStream]);

    useGamepads(pingpong.dataReady, pingpong.send);
    useKeyboard(pingpong.send);
    const { cb } = useMouse(pingpong.send);

    return <>
        <Box sx={{
            textAlign: "center", maxHeight: "100%", maxWidth: "100%", "> div > video": {
                width: "100vw", aspectRatio: "16 / 9", maxHeight: "100vh", maxWidth: "100vw", objectFit: 'contain',
                cursor: (pingpong.inputOptions.showMouse ? "default" : "none")
            }
        }}>
            {!remote || !dataReady ? <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>
                    <CardMedia component="img" image={process.env.PUBLIC_URL + "/playcast-gold.png"} alt="PlayCast Demo" sx={{ align: "center", maxHeight: "50vh", maxWidth: "300px", mb: "2rem" }} />
                </Grid>
                <Grid item xs={3} sx={{ mb: "3rem" }}>
                    <TextField defaultValue={init} onChange={e => setRemote(e.target.value)} sx={{ display: "none", zIndex: 1000 }} id="outlined-basic" label="Host ID" helperText="Get this from your playcaster, if not prefilled" variant="outlined" size="small" />
                    <Button onClick={() => { console.log('clicked join'); pingpong.connect(remote) }} sx={{ mx: "1rem", zIndex: 1000 }} variant="contained">Join Playcast</Button>
                </Grid>
            </Grid>
                : <Box sx={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    padding: "0.5rem",
                    zIndex: "10000",
                    opacity: "0.3",
                    display: "none"
                }}>
                    <CardMedia component="img" image={process.env.PUBLIC_URL + "/playcast-gold.png"} alt="PlayCast Demo" sx={{ align: "center", maxHeight: "50vh", maxWidth: "100px" }} />
                </Box>}
            <Box ref={cb}><video ref={videoPlayer} playsInline autoPlay onContextMenu={(e) => { e.preventDefault(); return false; }}></video></Box>
        </Box>
        <Box sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            padding: "0.5rem",
            color: "#00ff00",
            fontSize: "0.6rem",
            zIndex: "10000",
            opacity: "0.5",
            display: "none"
        }}>
            <Typography sx={{ fontSize: "0.75rem", textShadow: "1px 1px #000000" }}>PlayCast v0.9.4, build 6924</Typography>
            <Typography sx={{ fontSize: "0.75rem", textShadow: "1px 1px #000000" }}>Your player ID: {name}</Typography>
        </Box>
    </>
}
