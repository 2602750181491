import { Box, Button, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import Peer, { DataConnection } from 'peerjs';
import { config } from './config';

export const usePlaycaster = () => {

    const [name, setName] = useState<string>('');
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [doAutoReconnect, setDoAutoReconnect] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [stream, setStream] = useState<MediaStream>();
    const [remote, setRemote] = useState<string>('');
    
    const peer = useRef<Peer>();
    const dataCallback = useRef<(data: any) => void>((data) => { });

    const getNewPeer = (): Peer => {
        console.log('Creating new peer connection...');
        const newPeer = new Peer(name, config.peerServer);

        newPeer.on('error', (error) => {
            setMessage('Error: ' + error.type);
        });

        newPeer.on('open', () => {
            setIsConnected(true);
            setMessage('Connected to peer server');
        });

        // This "connection" means a remote peer has connected, not that this
        // peer connected to a peer server (which generates "open")
        newPeer.on('connection', (c: DataConnection) => {
            setMessage('Received remote peer connection: ' + c.peer);
            setRemote(c.peer);
            peer.current?.call(c.peer, stream!);
            c.on('data', (data) => {
                dataCallback.current(data);
            });

            c.on('error', (error) => {
                console.log(c.peer + ' error', error);
                // Dispose of bad connection
                // if (!player.current?.destroyed) {
                //     player.current?.destroy();
                // }
                setIsConnected(false);
            });

            c.on('close', () => {
                console.log(c.peer + ' closed');
                // Dispose of bad connection
                // if (!player.current?.destroyed) {
                //     player.current?.destroy();
                // }
                setIsConnected(false);
            });

            c.on('open', () => {
                console.log(c.peer + ' open');
                //connection.current = conn;
                c.send('I hear you');
            });

        });

        newPeer.on('disconnected', () => {
            setIsConnected(false);
            // Attempt reconnection automatically, if set
            if (doAutoReconnect) {
                newPeer.reconnect();
            }
        });

        newPeer.on('close', () => {
            console.log('Peer server connection destroyed');
            setIsConnected(false);
        });

        return newPeer;
    }

    const connect = () => {
        console.log('In connect...');
        if (!name) {
            setMessage('A host name is required to start allowing peer connections');
            return;
        }

        if (peer.current && !peer.current.destroyed) {
            // Already have a peer object, re-use if possible
            if (peer.current.id !== name) {
                // Trying to start a new peer by name, must make a new one
                peer.current.destroy();
                peer.current = getNewPeer();
            } else {
                if (!peer.current.disconnected) {
                    // Already connected with this ID, just continue
                    return;
                } else {
                    // Trying to reconnect with same name
                    peer.current.reconnect();
                }
            }
        } else {
            // No peer at all yet
            peer.current = getNewPeer();
        }
    }

    const disconnect = () => {
        // Kill all connections
        peer.current?.destroy();
        setIsConnected(false);
        setMessage('Disconnected from peer server');
        if (doAutoReconnect) {
            connect();
            setMessage('Attempting reconnection');
        }
    }

    const updateName = (target: string) => {
        setName(target);
    }

    const setAutoReconnect = (auto: boolean) => {
        setDoAutoReconnect(auto);
        setMessage('Autoreconnect set to ' + (auto ? 'true' : 'false'));
    }

    const setMediaStream = (s: MediaStream) => {
        setStream(s);
        setMessage('Acquired new media stream');
    }

    const setDataCallback = (f: (data: any) => void) => {
        dataCallback.current = f;
    }

    useEffect(() => {
        window.addEventListener('beforeunload', disconnect);
        return () => {
            window.removeEventListener('beforeunload', disconnect);
        }
    });

    return {
        name: name,
        peer: peer.current,
        remote: remote,
        message: message,
        connect: connect,
        disconnect: disconnect,
        setAutoReconnect: setAutoReconnect,
        autoreconnect: doAutoReconnect,
        connected: isConnected,
        updateName: updateName,
        setMediaStream: setMediaStream,
        setDataCallback: setDataCallback
    }
}

export const Playcaster = () => {

    const playcaster = usePlaycaster();

    const updateHost = (target: string) => {
        playcaster.updateName(target);
    }

    return <>
        <Box sx={{ m: '2rem' }}>
            <Box sx={{ mb: '2rem' }}>
                <Typography>Playcaster Connection</Typography>
                <Typography>Name: {playcaster.name || 'None'}</Typography>
                <Typography>Connected? {playcaster.connected ? 'true' : 'false'}</Typography>
                <Typography>Autoreconnect? {playcaster.autoreconnect ? 'true' : 'false'}</Typography>
            </Box>
            <Box sx={{ mb: '2rem' }}>
                <Stack>
                    <TextField disabled={playcaster.connected} onChange={e => updateHost(e.target.value)} sx={{ maxWidth: "30rem" }} label="Host ID" helperText="You set this, like alex-playcast-io" variant="outlined" size="small" />
                    <Box>
                        <Button disabled={!playcaster.name || playcaster.connected} onClick={playcaster.connect} variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }}>Connect</Button>
                        <Button disabled={!playcaster.connected} onClick={playcaster.disconnect} variant="contained" sx={{ width: "8rem", mt: "1rem", mr: "1rem" }}>Disconnect</Button>
                        <Button onClick={() => playcaster.setAutoReconnect(!playcaster.autoreconnect)} variant="contained" sx={{ mt: "1rem", mr: "1rem" }}>Toggle Autoreconnect</Button>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ mb: '2rem' }}>
                <pre>{JSON.stringify(playcaster.peer?.id, null, 4)}</pre>
                {playcaster.message ? <>
                    <Typography>Message:</Typography>
                    <pre>{playcaster.message}</pre>
                </> : ''}
            </Box>
        </Box>
    </>
}
